import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { load } from 'redux-localstorage-simple';
import { updateVersion } from './actions';
import team from './team/state';

export const reducers = {
  team,
};

const store = configureStore({
  reducer: reducers,
  middleware: [
    ...getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
    }),
  ],
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: load({ disableWarnings: true }),
});

store.dispatch(updateVersion());

export const { dispatch, getState } = store;
export default store;
