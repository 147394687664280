import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyDan3VT-fwwsQJV7fQFgsWTv5q6op96K8g',
  authDomain: 'dfk-user-api.firebaseapp.com',
  projectId: 'dfk-user-api',
  storageBucket: 'dfk-user-api.appspot.com',
  messagingSenderId: '181708927424',
  appId: '1:181708927424:web:6231bb3bf2b7b9f962d825',
};

const app = initializeApp(config);
const db = getFirestore(app);

export { app, db };
