import React from 'react';
import './Home.scss';
import serendale from '../images/dfk_serendale.png';
import crystalvale from '../images/dfk_crystalvale.png';
import dfkchain from '../images/dfk_chain.png';

function Home() {
  return (
    <div className="Home">
      <div className="width600 textCenter">
        <h1>Welcome to the Kingdom</h1>
        <p>
          Kingdom Studios is best known for being the project administrator for DeFi Kingdoms, a
          play-to-earn game that is changing the gamefi landscape. Kingdom Studios was created from
          a dream to embrace the full potential of the blockchain in game form. The team is
          realizing that dream by providing services to DeFi Kingdoms: Serendale, Crystalvale, and
          DeFi Kingdoms Blockchain.{' '}
        </p>
      </div>
      <div className="divider"></div>
      <div className="homeBanners">
        <a
          href="https://defikingdoms.medium.com/"
          className="banner"
          rel="noreferrer"
          target="_blank"
        >
          <div className="bannerImage">
            <img src={serendale} alt="DeFi Kingdoms Serendale" />
          </div>
          {/* <div className="bannerCaption">
            Caption here.
          </div> */}
        </a>
        <a
          href="https://defikingdoms.medium.com/introducing-the-crystalvale-realm-defi-kingdoms-is-coming-to-avalanche-d0730ba54b63"
          className="banner"
          rel="noreferrer"
          target="_blank"
        >
          <div className="bannerImage">
            <img src={crystalvale} alt="DeFi Kingdoms Crystalvale" />
          </div>
          {/* <div className="bannerCaption">
            Caption here.
          </div> */}
        </a>
        <a
          href="https://medium.com/defi-kingdoms-official/defi-kingdoms-announces-defi-kingdoms-blockchain-2d51333b1e4e"
          className="banner"
          rel="noreferrer"
          target="_blank"
        >
          <div className="bannerImage">
            <img src={dfkchain} alt="DFK Chain" />
          </div>
          {/* <div className="bannerCaption">
            Caption here.
          </div> */}
        </a>
      </div>
    </div>
  );
}

export default Home;
