function Contact() {
  return (
    <div className="Contact">
      <div className="width600">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLScDFERMhOSjps3BmepBQV6SE52_XVXH-8n6TktgYVroJ-pqNQ/viewform?embedded=true"
          width="640"
          height="1170"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          style={{ width: '100%' }}
          dataAos="fade-up"
          title="Contact Form"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
}

export default Contact;
