import React from "react";
import { NavLink, Link } from "react-router-dom";
import logo from './images/KingdomStudiosLogo.png';
import './header.scss';

function Header() {

  const [isOpen, setMenuStatus] = React.useState(false);

  return (
    <header>
      <div className="logo">
        <Link className="link" to="/">
          <img src={logo} alt="Kingdom Studios logo" />
        </Link>
      </div>
      <nav className={isOpen ? "open" : ""}>
        <button
          type="button"
          onClick={() => setMenuStatus((prevCheck) => !prevCheck)}
        >
          <i className="fa-solid fa-bars"></i>
        </button>
        <div className="navList">
          <NavLink className="link" to="/">Home</NavLink>
          <NavLink className="link" to="team">Team</NavLink>
          <NavLink className="link" to="contact">Contact</NavLink>
        </div>
      </nav>
    </header>  
  );
}
export default Header;