import './Team.scss';
import { useDispatch, useSelector } from '../features/hooks';
import { fetchTeamMembers } from '../features/team/state';
import { useEffect } from 'react';
import orgChart from '../images/kingdom-studios-org-chart.png';

function Team() {
  const dispatch = useDispatch();
  const { teamMembers, teamMembersLoading } = useSelector((s) => s.team);

  useEffect(() => {
    if (teamMembers.length <= 0) {
      dispatch(fetchTeamMembers());
    }
  }, []);

  return (
    <div className="Team">
      <h1 className="textCenter large">TEAM</h1>
      <div className="team padding-sm">
        <div className="content width-md">
          <div className="row pt">
            {teamMembersLoading ? (
              <></>
            ) : (
              <>
                {teamMembers.map((member) => (
                  <div className="col" key={member.name}>
                    <div className="detail-img textCenter">
                      <img
                        src={member.thumbnail}
                        style={member.whiteBackground ? { backgroundColor: 'white' } : {}}
                        alt=""
                      />
                    </div>
                    <h3>{member.name}</h3>
                    <div className="textCenter">
                      <span dangerouslySetInnerHTML={{ __html: member.title }} />
                      <br />
                      <small>{member.startDate}</small>
                      {/* {member.socials.length > 0 && (
                        <ul className="team-socials">
                          {member.socials?.map((social) => (
                            <li key={social.name}>
                              <a
                                href={social.link}
                                className={social.network}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span className="sr-only">{social.network}</span>
                              </a>
                              <span className="tooltip">{social.name}</span>
                            </li>
                          ))}
                        </ul>
                      )} */}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        <h2 className="textCenter large">ORG CHART</h2>
        <img src={orgChart} alt="" />
      </div>
    </div>
  );
}

export default Team;
