import React from "react";
import { NavLink } from "react-router-dom";
import './footer.scss';

function Footer() {
  return (
    <footer className="textCenter">
      <div className="divider"></div>
      <h1>Come on the Journey With Us</h1>
      <NavLink className="link" to="/">Home</NavLink>
      <NavLink className="link" to="team">Team</NavLink>
      <NavLink className="link" to="contact">Contact</NavLink>
    </footer>
  );
}

export default Footer;